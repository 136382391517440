export const mixcloudRefactor = (link) => {
	return `https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FISORadio%2F${link}%2F`;

	//www.mixcloud.com/ISORadio/the-konzai-show-with-dj-asey-5th-january-2023/

	// https: <iframe
	// 	width="100%"
	// 	height="120"
	// 	src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FISORadio%2Fthe-konzai-show-with-dj-asey-5th-january-2023%2F"
	// 	frameborder="0"
	// ></iframe>;
};
