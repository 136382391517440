import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Seo from "../components/seo";
import { mixcloudRefactor } from "../utils/mixcloud";
import { showDate } from "../utils/showDate";
import { soundcloud, guestMixes } from "../shows";

const DJ = () => {
	const [mixcloud, setmixcloud] = useState([]);

	useEffect(() => {
		fetch(
			"https://api.mixcloud.com/search/?q=asey+konzai&type=cloudcast&limit=200",
		)
			.then((res) => res.json())
			.then(({ data }) => {
				setmixcloud(
					data
						.filter((item) => item.user.name === "ISO Radio")
						.sort((a, b) => new Date(b.created_time) - new Date(a.created_time))
						.splice(0, 9),
				);
			});
	}, []);

	return (
		<>
			<Seo title="DJ" />
			<div
				className="container"
				style={{ display: "flex", flexDirection: "column" }}>
				<h1>Who is asey?</h1>
				<h3>
					Since 2016, Atina, ~ who goes by the DJ name <strong>ASEY</strong>
					(pronounced AC) ~ has been gracing clubs and bars across downtown
					Toronto and the world.
				</h3>
				<Link to="/" style={{ marginTop: "1.5rem" }}>
					Go Back
				</Link>
				<p>
					My monthly radio show,
					<a href="https://www.mixcloud.com/whoisasey/playlists/konzai-on-iso-radio/">
						The KONZAI Show
					</a>
					airs exclusively on
					<span>
						<a href="https://www.iso.fm/"> ISO Radio</a>
					</span>
				</p>
				<p>
					Follow on<a href="https://www.instagram.com/whoisasey/">Instagram</a>{" "}
					for the next live show!
				</p>
				<p>
					<strong>
						konzai - こんざい (noun): to exist alongside each other
					</strong>
				</p>
			</div>

			<div className="music__container">
				<p>Latest Show:</p>

				<div className="container__experience container__music">
					<iframe
						width="100%"
						height="166"
						style={{ border: "none" }}
						allow="autoplay"
						src={`${soundcloud[0].src}`}
						title="Soundcloud"></iframe>
				</div>

				<details>
					<summary>Shows Archive:</summary>

					{soundcloud.slice(1, 10).map(({ src }, i) => {
						return (
							<div className="container__experience container__music" key={i}>
								<iframe
									width="100%"
									height="166"
									style={{ border: "none" }}
									allow="autoplay"
									src={`${src}`}
									title="Soundcloud"></iframe>
							</div>
						);
					})}
					{mixcloud.map((show, i) => {
						return (
							<div className="container__experience container__music" key={i}>
								<iframe
									title={i}
									width="100%"
									height="120"
									src={mixcloudRefactor(show.slug)}
									style={{ border: "none" }}></iframe>
								{showDate(show.name)}
							</div>
						);
					})}
				</details>

				<p>Other Guest Mixes:</p>
				{guestMixes.map(({ src, title }, i) => {
					if (src.includes("soundcloud")) {
						return (
							<div className="container__experience container__music" key={i}>
								<iframe
									width="100%"
									height="166"
									style={{ border: "none" }}
									allow="autoplay"
									src={`${src}`}
									title={title}></iframe>
							</div>
						);
					}
					if (src.includes("youtube")) {
						return (
							<div className="container__experience container__music" key={i}>
								<iframe
									width="100%"
									height="315"
									src={`${src}`}
									title="YouTube video player"
									style={{ border: "none" }}
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									referrerPolicy="strict-origin-when-cross-origin"
									allowFullScreen></iframe>
								<p>{title}</p>
							</div>
						);
					}
					return;
				})}
			</div>
		</>
	);
};

export default DJ;
