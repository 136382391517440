export const soundcloud = [
	{
		src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1820260047&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
	},

	{
		src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1794729406&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
	},
	{
		src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1785082485&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
	},
];

export const guestMixes = [
	{
		src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1816335162&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
		title: "Bangkok Community Radio 🇹🇭 - April 28, 2024",
	},
	{
		src: "https://www.youtube.com/embed/SxK30jkvEmQ?si=AAMueSkw24gZhxQg",
		title: "Seoul Community Radio 🇰🇷 - April 25, 2024",
	},
	{
		src: "https://www.youtube.com/embed/P90pv7v7NHw?si=JYb0VOu4fHfL8Qzl",
		title: "V2X Radio 🇻🇳 - March 13, 2024 ",
	},
];
